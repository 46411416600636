import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { DataGrid, GridToolbar, GridToolbarExport } from '@mui/x-data-grid'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { useDispatch, useSelector } from 'react-redux'
import { reqUserList } from 'src/store/users/action'
import { AppFooter, AppHeader, AppSidebar } from 'src/components'
import Loader from 'src/components/Loader'

const UserList = () => {
  const [searchText, setSearchText] = useState('')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const usersData = useSelector((state) => state.Users?.usersList) || []
  const isUserLoading = useSelector((state) => state.Users?.userToggle)
  console.log(isUserLoading)
  useEffect(() => {
    dispatch(reqUserList())
  }, [dispatch])
  const columns = [
    { field: 'serial', headerName: 'S.No', width: 100 },
    {
      field: 'fullName',
      headerName: 'Full Name',
      width: 200,
      valueGetter: (params) => {
        const firstName = params.row.firstName || ''
        const lastName = params.row.lastName || ''
        return `${firstName} ${lastName}`.trim()
      },
    },
    {
      field: 'mobileNumber',
      headerName: 'Mobile No.',
      width: 180,
      valueGetter: (params) => {
        const countryCode = params.row.mobileNumber?.countryCode || ''
        const number = params.row.mobileNumber?.value || 'N/A'
        return countryCode ? `+${countryCode} ${number}` : number
      },
    },
    { field: 'userType', headerName: 'UserType', width: 150 },
    { field: 'handle', headerName: 'Handle', width: 150 },
    { field: 'roles', headerName: 'Role', width: 150 },
    { field: 'status', headerName: 'Status', width: 100 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 250,
      renderCell: (params) => (
        <div>
          <Button
            variant="outlined"
            color="primary"
            style={{ marginRight: '10px' }}
            onClick={() => navigate(`/UserUpdate/${params.row._id}`)}
          >
            Update
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigate(`/UserDetails/${params.row._id}`)}
          >
            Details
          </Button>
        </div>
      ),
    },
  ]
  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value)
  }
  const getFilteredRows = () => {
    return usersData
      ?.map((row, index) => ({
        serial: index + 1,
        id: row._id, // Adding the required 'id' property
        ...row, // Spread the existing properties of the row
      }))
      .filter((row) =>
        Object.values(row).some(
          (value) => value.toString().toLowerCase().indexOf(searchText.toLowerCase()) > -1,
        ),
      )
  }
  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          {isUserLoading && <Loader />}
          {!isUserLoading && (
            <DataGrid
              rows={getFilteredRows()}
              columns={columns}
              components={{ Toolbar: GridToolbar }}
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              pageSizeOptions={[10, 15, 25]}
              disableColumnMenu
              sx={{
                '& .MuiDataGrid-columnHeaders': {
                  position: 'sticky',
                  top: 0,
                  zIndex: 1000,
                  // backgroundColor: 'white',
                },
              }}
            >
              {(params) => (
                <TextField
                  variant="standard"
                  value={searchText}
                  onChange={handleSearchTextChange}
                  placeholder="Search…"
                  fullWidth
                />
              )}
              <GridToolbarExport disableExport />
            </DataGrid>
          )}
        </div>
        <AppFooter />
      </div>
    </div>
  )
}

export default UserList
